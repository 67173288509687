import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Seo from 'components/SEO'
import Header from 'components/HeaderSection'
import MarkdownOverlay from 'components/MarkdownOverlaySection'
import Resources from './Resources'

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { seo, header, resourcesSection, firstDaySection } = frontmatter
  return (
    <Fragment>
      <Seo title={seo.title} description={seo.description} />
      <Helmet title={seo.title} />
      <Header
        title={header.title}
        src={header.image.childImageSharp.gatsbyImageData.images.fallback.src}
        content={header.description}
        color="lightBlue"
      />
      <Resources
        title={resourcesSection.title}
        src={
          resourcesSection.image.childImageSharp.gatsbyImageData.images.fallback
            .src
        }
        color="lightBlue"
        fbGroups={resourcesSection.facebookGroups}
      />
      <MarkdownOverlay
        src={
          firstDaySection.image.childImageSharp.gatsbyImageData.images.fallback
            .src
        }
        content={firstDaySection.description}
        color="lightBlue"
      />
    </Fragment>
  )
}

export const query = graphql`
  query NewParentsPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
        }
        header {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(sizes: "2200")
            }
          }
        }
        resourcesSection {
          title
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          facebookGroups {
            description
            link
            linkImage {
              childImageSharp {
                gatsbyImageData(sizes: "60")
              }
            }
          }
        }
        firstDaySection {
          description
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
