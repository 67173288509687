import React from 'react'
import styled from 'styled-components'

import OverlayBottom from 'components/OverlayBottom'
import Heading from 'components/Heading'
import Markdown from 'components/styled/Markdown'

const Title = styled(Heading)`
  text-transform: uppercase;
`

export default ({ color, title, content, src }) => {
  return (
    <OverlayBottom src={src}>
      <Title color={color}>{title}</Title>
      <Markdown color={color} children={content} />
    </OverlayBottom>
  )
}
