import React from 'react'
import styled from 'styled-components'
import OverlayBottom from 'components/OverlayBottom'
import MD from 'components/styled/Markdown'

const Markdown = styled(MD)`
  & > p {
    padding-bottom: 30px;
    border-bottom: solid 1px #dedede;
  }

  & > p:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }

  & strong {
    color: ${(props) => props.theme.primaryColor};
  }
`

export default ({ color, title, content, src }) => (
  <OverlayBottom src={src} imageSize="large" imageOverlayColor={color}>
    <Markdown color={color} children={content} />
  </OverlayBottom>
)
