import React from 'react'
import styled from 'styled-components'
import SplitScreen from 'components/SplitScreen'
import Heading from 'components/Heading'
import Markdown from 'components/styled/Markdown'

export default ({
  title,
  src,
  color,
  fbGroups,
  // ezSmilesDescription,
  // appleStoreLink,
  // appleStoreImage,
  // playStoreLink,
  // playStoreImage
}) => (
  <SplitScreen color={color} src={src}>
    <Heading>{title}</Heading>

    {fbGroups.map((group, i) => (
      <React.Fragment key={i}>
        <Description children={group.description} />
        <ResourceLinks>
          <a href={group.link} target="_blank" rel="noopener noreferrer">
            <FbLogo
              src={
                group.linkImage.childImageSharp.gatsbyImageData.images.fallback
                  .src
              }
            />
          </a>
        </ResourceLinks>
      </React.Fragment>
    ))}
  </SplitScreen>
)

const Description = styled(Markdown)`
  & p {
    color: white;
  }

  & p strong {
    font-weight: 600;
    color: black;
  }
`
const ResourceLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`
// const AppStoreLink = styled.a`
//   margin: 5px;
//   background: url(${props => props.img}) no-repeat;
//   width: 200px;
//   height: 60px;
//   background-size: contain;
// `

const FbLogo = styled.img`
  width: auto;
  height: 60px;
  margin-bottom: 10px;
`
