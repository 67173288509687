import React from 'react'
import styled from 'styled-components'

export default ({ color, src, children }) => (
  <Container>
    <Image image={src} />
    <Card color={color}>{children}</Card>
  </Container>
)

const Container = styled.section`
  display: flex;
`
const Card = styled.div`
  background-color: ${(props) => props.theme[props.color]};
  box-shadow: ${(props) => props.theme.boxShadow};
  padding: 60px 85px 65px;
  width: 55%;
  color: white;

  @media (max-width: ${(props) => props.theme.desktop}px) {
    margin: 0;
    width: 100%;
    padding: 6% 4%;
  }

  @media (max-width: 587px) {
    padding: 60px 10px 65px;
  }
`
const Image = styled.div`
  margin: 0;
  padding: 0;
  width: 45%;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center center;

  @media (max-width: ${(props) => props.theme.desktop}px) {
    display: none;
  }
`
